import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { FACILITY_OBJECTS_REDUCER_PATH } from "../reducerPaths";
import { BASE_URL, prepareHeaders } from "./utils";
import {
  FacilityObject,
  FacilityObjectRequest,
  FacilityUnitWithTenanciesOrOwnershipsAndPersons,
  ServiceProvider,
  NavigationItem,
  NavigationRawItem,
  Neighbor,
  FacilityObjectContact,
  Contact,
  CustomerRequest,
  ContactPictureRequest,
  FacilityObjectPictureRequest,
  UsersRegistrationStatus,
} from "../types";
import { mapRawNavigationItems } from "../lib/navigation";
import { CommunicationDataType } from "../enums";

export const facilityManagementApi = createApi({
  reducerPath: FACILITY_OBJECTS_REDUCER_PATH,
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders,
  }),
  tagTypes: ["FacilityObjects", "UsersRegistrationStatus", "AssignedObjects"],
  endpoints: (build) => ({
    getFacilityObject: build.query<FacilityObject, FacilityObjectRequest>({
      query: ({ customerToken, facilityObjectId }) =>
        `/customers/${customerToken}/facilityObjects/${facilityObjectId}`,
    }),
    getAssignedObjects: build.query<FacilityObject[], CustomerRequest>({
      query: ({ customerToken }) => ({
        url: `/v2/customers/${customerToken}/assignedObjects`,
      }),
      providesTags: [{ type: "AssignedObjects", id: "OBJECTS" }],
    }),
    getFacilityObjects: build.query<FacilityObject[], CustomerRequest>({
      query: ({ customerToken, ...params }) => ({
        url: `/v2/customers/${customerToken}/facilityObjects`,
        params,
      }),
      providesTags: ["FacilityObjects"],
    }),
    setFacilityObjectOffline: build.mutation<void, FacilityObjectRequest>({
      query: ({ customerToken, facilityObjectId }) => ({
        url: `/customers/${customerToken}/facilityObjects/${facilityObjectId}/gooffline`,
        method: "POST",
      }),
      invalidatesTags: ["FacilityObjects"],
    }),
    setFacilityObjectOnline: build.mutation<void, FacilityObjectRequest>({
      query: ({ customerToken, facilityObjectId }) => ({
        url: `/customers/${customerToken}/facilityObjects/${facilityObjectId}/goonline`,
        method: "POST",
      }),
      invalidatesTags: ["FacilityObjects"],
    }),
    getFacilityObjectNavigationItems: build.query<
      NavigationItem[],
      FacilityObjectRequest
    >({
      query: ({ customerToken, facilityObjectId }) =>
        `/customers/${customerToken}/facilityObjects/${facilityObjectId}/navigationItems`,
      transformResponse: (response: NavigationRawItem[]) =>
        mapRawNavigationItems(response),
    }),

    getFacilityObjectPicture: build.query<Blob, FacilityObjectPictureRequest>({
      query: ({ customerToken, facilityObjectId, ...params }) => ({
        url: `/customers/${customerToken}/facilityObjects/${facilityObjectId}/picture`,
        params,
        responseHandler: async (response) => {
          if (response.status === 200) {
            const blob = await response.blob();
            return Promise.resolve(blob);
          }
          return Promise.resolve(null);
        },
      }),
    }),

    getFacilityUnitsWithTenanciesOrOwnerships: build.query<
      FacilityUnitWithTenanciesOrOwnershipsAndPersons[],
      FacilityObjectRequest
    >({
      query: ({ customerToken, facilityObjectId }) =>
        `/customers/${customerToken}/facilityObjects/${facilityObjectId}/unitsWithTenanciesOrOwnerships`,
    }),
    getFacilityUnitsWithTenanciesAndOwnershipsIncludingFuture: build.query<
      FacilityUnitWithTenanciesOrOwnershipsAndPersons,
      FacilityObjectRequest
    >({
      query: ({ customerToken, facilityObjectId }) =>
        `/customers/${customerToken}/facilityObjects/${facilityObjectId}/withTenanciesAndOwnershipsIncludingFuture`,
    }),
    getServiceProviders: build.query<Contact[], FacilityObjectRequest>({
      query: ({ customerToken, facilityObjectId }) =>
        `/customers/${customerToken}/facilityObjects/${facilityObjectId}/persons/serviceProviders`,
      transformResponse: (response: ServiceProvider[]) =>
        response.map(
          ({
            responsibilities,
            contractedResources,
            resourceGroups,
            personId,
            firstName,
            lastName,
            communicationData,
            contactPerson,
            ...rest
          }) => {
            const hasContactPerson =
              contactPerson && Object.keys(contactPerson).length > 0;
            const personCommunicationData = hasContactPerson
              ? contactPerson.communicationData
              : communicationData;
            return {
              id: personId,
              firstName: hasContactPerson ? contactPerson.firstName : firstName,
              lastName: hasContactPerson ? contactPerson.lastName : lastName,
              resourceGroups: resourceGroups.map(
                ({ resourceGroupName }) => resourceGroupName,
              ),
              ...rest,
              responsibilities: responsibilities.map(
                (item): string => item.responsibilityName,
              ),
              contractedResources: contractedResources.map(
                (item): string => item.resourceName,
              ),
              communication: {
                emails: personCommunicationData
                  .filter((item) => item.type === CommunicationDataType.Email)
                  .map(({ value }) => value),
                phones: personCommunicationData
                  .filter((item) => item.type === CommunicationDataType.Phone)
                  .map(({ value }) => value),
                fax: personCommunicationData
                  .filter((item) => item.type === CommunicationDataType.Fax)
                  .map(({ value }) => value),
                website: personCommunicationData
                  .filter((item) => item.type === CommunicationDataType.WebSite)
                  .map(({ value }) => value),
              },
            };
          },
        ),
    }),
    getNeighbors: build.query<Contact[], FacilityObjectRequest>({
      query: ({ customerToken, facilityObjectId }) =>
        `/customers/${customerToken}/facilityObjects/${facilityObjectId}/neighbors`,
      transformResponse: (response: Neighbor[]) =>
        response.map(
          ({
            dayOfBirth,
            monthOfBirth,
            yearOfBirth,
            sid,
            aboutMe,
            gender,
            firstName,
            lastName,
          }) => ({
            id: sid,
            aboutMe,
            gender,
            firstName,
            lastName,
            birthDate: {
              dayOfBirth,
              monthOfBirth,
              yearOfBirth,
            },
          }),
        ),
    }),

    getContactPicture: build.query<string, ContactPictureRequest>({
      query: ({ customerToken, employeeId, visitingCardId, ...params }) => ({
        url: `customers/${customerToken}/employees/${employeeId}/visitingCards/${visitingCardId}/picture`,
        params,
        responseHandler: async (response) => {
          if (response.status === 200) {
            const url = await response.url;
            return Promise.resolve(url);
          }
          return Promise.resolve(null);
        },
      }),
    }),

    getContacts: build.query<Contact[], FacilityObjectRequest>({
      query: ({ customerToken, facilityObjectId }) =>
        `/customers/${customerToken}/facilityObjects/${facilityObjectId}/contacts`,
      transformResponse: (response: FacilityObjectContact[]) =>
        response.map(
          ({
            emails,
            phones,
            mobiles,
            firstName,
            lastName,
            firma,
            roleName,
            employeeId,
            visitingCardId,
          }) => ({
            employeeId,
            visitingCardId,
            firstName,
            lastName,
            firma,
            roleName,
            communication: {
              emails,
              phones,
              mobiles,
            },
          }),
        ),
    }),

    getUsersRegistrationStatus: build.query<
      UsersRegistrationStatus,
      CustomerRequest
    >({
      query: ({ customerToken }) =>
        `/customers/${customerToken}/usersRegistrationStatus`,
      providesTags: ["UsersRegistrationStatus"],
    }),

    sendRegistrationCodes: build.mutation<void, CustomerRequest>({
      query: ({ customerToken }) => ({
        url: `/customers/${customerToken}/sendRegistrationCodes`,
        method: "POST",
      }),
      invalidatesTags: ["UsersRegistrationStatus"],
    }),
  }),
});

export const {
  useLazyGetAssignedObjectsQuery,
  useGetFacilityObjectQuery,
  useGetFacilityObjectsQuery,
  useGetFacilityUnitsWithTenanciesOrOwnershipsQuery,
  useGetServiceProvidersQuery,
  useGetFacilityObjectNavigationItemsQuery,
  useGetNeighborsQuery,
  useGetContactsQuery,
  useGetContactPictureQuery,
  useGetFacilityObjectPictureQuery,
  useSetFacilityObjectOfflineMutation,
  useSetFacilityObjectOnlineMutation,
  useGetFacilityUnitsWithTenanciesAndOwnershipsIncludingFutureQuery,
  useGetUsersRegistrationStatusQuery,
  useSendRegistrationCodesMutation,
} = facilityManagementApi;
