import { ReactElement, ReactNode, SelectHTMLAttributes } from "react";
import CaretDown from "../icons/CaretDown";
import { FormFieldOption } from "../../types";

import "../../styles/components/common/form/Select.scss";
import classNames from "classnames";
import { AnyObject } from "final-form";

export default function SelectField({
  className,
  required,
  options = [],
  defaultOption,
  label,
  children,
  classNameForSelect,
  ...rest
}: Props): ReactElement {
  return (
    <section className={classNames("select-field", className)}>
      <label>
        {label && (
          <span className="select-field-label" role="label">
            {label} {required && "*"}
          </span>
        )}
        <div className={classNames("select-field-wrapper", classNameForSelect)}>
          <select {...rest} required={required} data-testid="select-field">
            {defaultOption && <option value="">{defaultOption}</option>}
            {options.map(
              (
                { label, value, disabled, key, selected }: FormFieldOption,
                index: number,
              ) => (
                <option
                  value={value}
                  key={key || index}
                  disabled={disabled}
                  selected={selected}
                >
                  {label}
                </option>
              ),
            )}
          </select>
          <span className="select-field-caret">
            <CaretDown height={16} width={16} />
          </span>
        </div>
      </label>
      {children}
    </section>
  );
}

type Props = SelectHTMLAttributes<HTMLSelectElement> & {
  options: FormFieldOption[] | AnyObject;
  defaultOption?: string;
  label?: string;
  className?: string;
  children?: ReactNode;
  classNameForSelect?: string;
};
