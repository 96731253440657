import React, { Fragment } from "react";
import { User } from "../../types";
import ProfilePicture from "./ProfilePicture";
import { getUserFullName } from "../../lib/notifications";
import Menu from "./Menu";
import MenuListItem from "./menu/MenuListItem";

import "../../styles/components/common/ProfilePicture.scss";

export default function ProfilePicturesBunch({
  users,
  maxVisibleProfilesCount,
}: Props): JSX.Element {
  const predefinedMaxVisibleProfilePicturesCount = 6;
  const maxVisibleProfilePicturesCount =
    maxVisibleProfilesCount ?? predefinedMaxVisibleProfilePicturesCount;

  const visibleUsers =
    users.length > maxVisibleProfilePicturesCount
      ? users.slice(0, maxVisibleProfilePicturesCount - 1)
      : users;

  const visibleCountDifference = users.length - visibleUsers.length;
  const menuButton = (
    <div className="profile-picture profile-picture-rounded" role="button">
      <span className="profile-picture-bunch-count-difference">
        +{visibleCountDifference}
      </span>
    </div>
  );

  return (
    <Fragment>
      <div
        className="profile-picture-bunch"
        data-testid="profile-picture-bunch"
      >
        {visibleUsers.map((u) => (
          <ProfilePicture
            alt={getUserFullName(u)}
            userSid={u.sid}
            rounded
            width={32}
            height={32}
            key={`ProfilePicturesBunch-${u.sid}`}
          />
        ))}
        {visibleCountDifference > 0 && (
          <Menu
            button={menuButton}
            header="Wer sieht diese Nachricht?"
            footer="Mitarbeiter Ihrer Hausverwaltung haben auch Zugriff auf diese Nachricht."
          >
            {users.map((u) => {
              const leadingIcon = (
                <ProfilePicture
                  alt={getUserFullName(u)}
                  userSid={u.sid}
                  rounded
                  width={32}
                  height={32}
                />
              );
              return (
                <MenuListItem leadingIcon={leadingIcon} key={u.sid}>
                  {getUserFullName(u)}
                </MenuListItem>
              );
            })}
          </Menu>
        )}
      </div>
    </Fragment>
  );
}

type Props = {
  users: User[];
  maxVisibleProfilesCount?: number;
};
