import classNames from "classnames";
import React, {
  ReactElement,
  SyntheticEvent,
  useCallback,
  useRef,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import { BulletinBoardPost } from "../../types";
import PostAttachments from "./PostAttachments";
import PostMenu from "./PostMenu";
import PostComments from "./PostComments";
import PostByUser from "./PostByUser";
import ConfirmDialog from "../common/dialog/ConfirmDialog";
import {
  useDeleteBulletinBoardPostMutation,
  usePinBulletinBoardPostMutation,
  useUnpinBulletinBoardPostMutation,
  useEnableBulletinBoardPostTenantCommentingMutation,
  useDisableBulletinBoardPostTenantCommentingMutation,
} from "../../api/bulletinBoardApi";
import PostEdit from "./PostEdit";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import LightboxProvider from "../common/lightbox/LightboxProvider";
import Lightbox from "../common/lightbox/Lightbox";
import PostReport from "./PostReport";
import LinkifyContent from "./LinkifyContent";

import "../../styles/components/bulletinBoard/PostListItem.scss";
import {
  BulletinBoardPostPinningType,
  BulletinBoardPostUpdateTenantCommentingAbilityType,
} from "../../enums";
import Pin from "../icons/Pin";
import DeactivatedComments from "../icons/DeactivatedComments";

export default function PostListItem({
  post,
  activeUserSid,
  page,
  inNotifications,
}: Props): ReactElement {
  const ref = useRef(null);
  const { customerToken = "", facilityObjectId = "" } = useParams();
  const [showDelete, setShowDelete] = useState(false);
  const [showPinDialog, setShowPinDialog] = useState(false);
  const [
    showUpdateTenantCommentingDialog,
    setShowUpdateTenantCommentingDialog,
  ] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showReport, setShowReport] = useState(false);

  const [deletePost] = useDeleteBulletinBoardPostMutation();

  const [pinPost] = usePinBulletinBoardPostMutation();
  const [unpinPost] = useUnpinBulletinBoardPostMutation();

  const [enablePostCommenting] =
    useEnableBulletinBoardPostTenantCommentingMutation();
  const [disablePostCommenting] =
    useDisableBulletinBoardPostTenantCommentingMutation();

  const { createdByUser, createDateUTC } = post;

  const handleCancelDelete = useCallback(() => {
    setShowDelete(false);
  }, []);

  const handeDeletePost = useCallback(
    (e: SyntheticEvent<HTMLButtonElement>) => {
      const applyToCurrentBulletinBoardOnly =
        e.currentTarget.id === "dialog-actions-confirm-1";

      deletePost({
        customerToken,
        facilityObjectId,
        bulletinBoardPostId: post.id,
        page,
        applyToCurrentBulletinBoardOnly,
      });
      setShowDelete(false);
    },
    [deletePost],
  );

  const handlePin = useCallback(() => {
    if (post.isMultipost && !showPinDialog) {
      setShowPinDialog(true);
      return;
    }
    pinApiHandler(BulletinBoardPostPinningType.AffectAllRelatedBulletinBoards);
  }, [post.pinned, showPinDialog]);

  const handleConfirmPin = useCallback(
    (e: SyntheticEvent) => {
      const label = (e.currentTarget as HTMLButtonElement).textContent;
      if (label === "Alle Objekte") {
        pinApiHandler(
          BulletinBoardPostPinningType.AffectAllRelatedBulletinBoards,
        );
      } else {
        pinApiHandler(
          BulletinBoardPostPinningType.AffectOnlyCurrentBulletinBoard,
        );
      }
      setShowPinDialog(false);
    },
    [post],
  );

  const pinApiHandler = useCallback(
    (pinningType: BulletinBoardPostPinningType) => {
      const params = {
        customerToken,
        facilityObjectId,
        bulletinBoardPostId: post.id,
        pinningType,
      };
      if (post.pinned) {
        unpinPost(params);
      } else {
        pinPost(params);
      }
    },
    [post],
  );

  const handleUpdateTenantCommenting = useCallback(() => {
    if (post.isMultipost && !showUpdateTenantCommentingDialog) {
      setShowUpdateTenantCommentingDialog(true);
      return;
    }
    updateTenantCommentingApiHandler(
      BulletinBoardPostUpdateTenantCommentingAbilityType.AffectAllRelatedBulletinBoards,
    );
  }, [post.tenantsCanComment, showUpdateTenantCommentingDialog]);

  const handleConfirmUpdateTenantCommenting = useCallback(
    (e: SyntheticEvent) => {
      const label = (e.currentTarget as HTMLButtonElement).textContent;
      if (label === "Alle Objekte") {
        updateTenantCommentingApiHandler(
          BulletinBoardPostUpdateTenantCommentingAbilityType.AffectAllRelatedBulletinBoards,
        );
      } else {
        updateTenantCommentingApiHandler(
          BulletinBoardPostUpdateTenantCommentingAbilityType.AffectOnlyCurrentBulletinBoard,
        );
      }
      setShowUpdateTenantCommentingDialog(false);
    },
    [post],
  );

  const updateTenantCommentingApiHandler = useCallback(
    (
      updateTenantCommentingAbilityType: BulletinBoardPostUpdateTenantCommentingAbilityType,
    ) => {
      const params = {
        customerToken,
        facilityObjectId,
        bulletinBoardPostId: post.id,
        updateTenantCommentingAbilityType,
      };
      if (post.tenantsCanComment) {
        disablePostCommenting(params);
      } else {
        enablePostCommenting(params);
      }
    },
    [post],
  );

  const handleDelete = useCallback(() => {
    setShowDelete(true);
  }, []);

  const handleEdit = useCallback(() => {
    setShowEdit(true);
  }, []);

  const handleToggleReport = useCallback(() => {
    setShowReport(!showReport);
  }, [showReport]);

  useOnClickOutside(ref, () => {
    setShowEdit(false);
  });

  const handleEditSuccess = useCallback(() => {
    setShowEdit(false);
  }, []);

  const deletePostConfirmDialogDescription = post.isMultipost ? (
    <div>
      <p>
        {`Der Beitrag "${post.text}" wurde auf mehreren Objekten erstellt.`}
      </p>
      <br />
      <p>
        Möchten Sie diesen Beitrag von allen Objekten oder nur diesem Objekt
        löschen?
      </p>
    </div>
  ) : (
    <div>{`Möchten Sie wirklich den Beitrag "${post.text}" löschen`}</div>
  );

  const deletePostConfirmDialogLabel = post.isMultipost
    ? ["Von allen Objekten Löschen", "Nur von diesem Objekt Löschen"]
    : ["Löschen"];

  const pinDialogDescription = post.pinned
    ? "Möchten Sie die Fixierung des Multi-Post Beitrags für alle Objekte aufheben?"
    : "Soll die Fixierung des Multi-Post Beitrags für alle Objekte erfolgen?";

  const updateTenantCommentingDialogDescription = post.tenantsCanComment
    ? "Möchten Sie das Kommentieren dieses Beitrages auf allen Objekten deaktivieren?"
    : "Möchten Sie das Kommentieren dieses Beitrages auf allen Objekten aktivieren?";

  return (
    <div
      className={classNames("bulletin-board-post-list-item", {
        unread: inNotifications,
      })}
      ref={ref}
      data-testid="post-list-item"
    >
      <div className="bulletin-board-post-list-item-user">
        <PostByUser
          createdByUser={createdByUser}
          createDateUTC={createDateUTC}
        />
        {(post.pinned || !post.tenantsCanComment) && (
          <div className="bulletin-board-post-list-item-status">
            {post.pinned && (
              <Pin className="bulletin-board-post-list-item-status-pinned" />
            )}
            {!post.tenantsCanComment && (
              <DeactivatedComments className="bulletin-board-post-list-item-status-deactivated-comments" />
            )}
          </div>
        )}
      </div>
      <div className="bulletin-board-post-list-item-container">
        <div className="bulletin-board-post-list-item-content">
          {!showEdit && (
            <LinkifyContent linkify={post.createdByUser.isEmployee}>
              <p
                className="bulletin-board-post-list-item-text"
                data-testid="post-item-text"
              >
                {post.text}
              </p>
            </LinkifyContent>
          )}
          {showEdit && (
            <PostEdit
              postId={post.id}
              text={post.text}
              page={page}
              onEditSuccessful={handleEditSuccess}
            />
          )}
          {!showEdit && (
            <LightboxProvider options={{ showMeta: true }}>
              <PostAttachments
                postId={post.id}
                attachmentCount={post.attachmentsCount}
              />
              <Lightbox lazy />
            </LightboxProvider>
          )}
          <PostComments
            commentsCount={post.commentsCount}
            postId={post.id}
            canComment={post.canComment}
            activeUserSid={activeUserSid}
          />
        </div>
      </div>
      {showPinDialog && (
        <ConfirmDialog
          description={pinDialogDescription}
          title={post.pinned ? "Fixierung entfernen" : "Beitrag fixieren"}
          onConfirm={handleConfirmPin}
          onCancel={() => setShowPinDialog(false)}
          confirmLabel={["Alle Objekte", "Dieses Objekt"]}
        />
      )}
      {showUpdateTenantCommentingDialog && (
        <ConfirmDialog
          description={updateTenantCommentingDialogDescription}
          title={
            post.tenantsCanComment
              ? "Kommentieren deaktivieren"
              : "Kommentare zulassen"
          }
          onConfirm={handleConfirmUpdateTenantCommenting}
          onCancel={() => setShowUpdateTenantCommentingDialog(false)}
          confirmLabel={["Alle Objekte", "Dieses Objekt"]}
        />
      )}
      {showDelete && (
        <ConfirmDialog
          description={deletePostConfirmDialogDescription}
          title="Beitrag löschen"
          onConfirm={handeDeletePost}
          onCancel={handleCancelDelete}
          confirmLabel={deletePostConfirmDialogLabel}
        />
      )}
      {showReport && (
        <PostReport postId={post.id} onChancel={handleToggleReport} />
      )}
      <PostMenu
        post={post}
        handleDelete={handleDelete}
        handleEdit={handleEdit}
        handlePin={handlePin}
        handleUpdateTenantCommenting={handleUpdateTenantCommenting}
        handleReport={handleToggleReport}
      />
    </div>
  );
}

type Props = {
  post: BulletinBoardPost;
  activeUserSid: string | number;
  page: number;
  inNotifications?: boolean;
};
