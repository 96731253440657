import moment from "moment";
import { Fragment } from "react";
import { ActivityDocument } from "../../types";
import OtherFile from "../common/files/OtherFile";
import LoadingSpinner from "../icons/LoadingSpinner";
import { PREVIEW_COMPONENTS } from "../../lib/messages";
import useOpenActivityDocument from "../../hooks/messages/useOpenActivityDocument";

import "../../styles/components/messages/MessageDetailsDocumentItem.scss";

const FILE_SIZE_DIFFER = 32;
const FILE_SIZE_DIFFER_DATE = 44;

export default function MessageDetailsDocumentItem({
  document,
  size,
  showUploadDate,
}: Props): JSX.Element {
  const { open, type, file } = useOpenActivityDocument(document);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const PreviewComponent = PREVIEW_COMPONENTS[type] || OtherFile;

  const style = {
    maxWidth: `${size}px`,
  };
  const calculatedSize =
    size - (showUploadDate ? FILE_SIZE_DIFFER_DATE : FILE_SIZE_DIFFER);

  return (
    <div
      className="message-details-document-item"
      style={style}
      data-testid="message-details-document-item"
    >
      {file !== null && (
        <Fragment>
          <PreviewComponent
            file={file}
            width={calculatedSize}
            height={calculatedSize}
            downloadable
            onClick={open}
          />
          {showUploadDate && (
            <span
              className="message-details-document-item-date"
              title={`${moment
                .utc(document.createDateUTC)
                .local()
                .format("LLL")} Uhr`}
            >
              {moment.utc(document.createDateUTC).local().format("lll")} Uhr
            </span>
          )}
          <span
            className="message-details-document-item-name"
            title={file.name}
          >
            {file.name}
          </span>
        </Fragment>
      )}
      {file === null && <LoadingSpinner />}
    </div>
  );
}

type Props = {
  document: ActivityDocument;
  size: number;
  showUploadDate?: boolean;
};
