import React, { ReactElement } from "react";
import { IconProps } from "../../types";

export default function Pin({
  width = 24,
  height = 24,
  className,
}: IconProps): ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 -960 960 960"
      className={className}
    >
      <path
        className={className ? `${className}-path` : undefined}
        d="m640-480 80 80v80H520v240l-40 40-40-40v-240H240v-80l80-80v-280h-40v-80h400v80h-40v280Zm-286 80h252l-46-46v-314H400v314l-46 46Zm126 0Z"
      />
    </svg>
  );
}
