import React, { ReactElement } from "react";
import { IconProps } from "../../types";

export default function DeactivatedComments({
  width = 24,
  height = 24,
  className,
}: IconProps): ReactElement {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 502.000000 510.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        className={className ? `${className}-path` : undefined}
        transform="translate(0.000000,510.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none"
      >
        <path d="M2390 4759 c-116 -7 -164 -13 -295 -39 -106 -20 -211 -52 -330 -99 -683 -272 -1181 -871 -1314 -1581 -28 -150 -33 -218 -33 -404 1 -245 19 -378 81 -598 17 -61 29 -93 67 -188 218 -543 650 -972 1199 -1191 163 -64 251 -87 475 -124 130 -22 470 -22 600 0 161 26 189 32 303 64 307 87 623 272 871 511 222 214 389 461 500 740 45 113 55 140 86 255 83 307 83 768 1 1065 -26 92 -30 107 -39 135 -55 171 -173 405 -282 559 -169 238 -416 466 -660 608 -384 224 -768 313 -1230 287z m396 -409 c148 -26 203 -40 309 -77 39 -14 79 -28 90 -31 45 -13 249 -124 336 -183 134 -90 354 -313 445 -449 154 -230 239 -447 284 -728 15 -92 15 -398 1 -483 -39 -222 -90 -379 -182 -556 -51 -98 -136 -230 -164 -253 -15 -12 -136 106 -1222 1193 -1087 1086 -1205 1207 -1193 1222 23 28 150 110 251 163 179 93 313 137 544 180 69 13 429 14 501 2z m-387 -1854 c1085 -1085 1203 -1206 1191 -1221 -23 -28 -150 -110 -251 -163 -268 -139 -485 -192 -800 -192 -173 0 -223 4 -354 31 -300 60 -599 218 -824 434 -303 291 -456 583 -531 1009 -13 76 -13 419 0 488 6 29 15 78 21 108 28 144 84 300 162 449 67 129 156 261 176 261 3 0 548 -542 1210 -1204z" />
        <path d="M2295 3749 c-27 -5 -79 -14 -115 -20 -36 -6 -76 -14 -90 -19 -14 -4 -44 -13 -67 -19 -24 -7 -43 -17 -43 -24 0 -7 360 -372 799 -811 l800 -800 26 14 c62 33 221 229 267 330 58 125 71 188 72 340 0 147 -16 218 -77 344 -120 249 -383 459 -732 585 -79 28 -176 50 -334 76 -104 17 -420 19 -506 4z" />
        <path d="M1357 3298 c-8 -13 -23 -32 -34 -43 -59 -62 -124 -185 -163 -305 -20 -63 -24 -97 -24 -205 -1 -80 4 -149 12 -180 49 -179 145 -333 293 -467 130 -118 237 -183 447 -269 17 -8 32 -19 32 -25 0 -17 -110 -123 -170 -163 -59 -40 -118 -69 -159 -81 -14 -3 -27 -14 -29 -23 -3 -15 9 -17 115 -17 223 1 426 55 611 163 l58 34 195 0 c107 0 221 5 254 11 33 6 76 14 95 17 19 3 42 10 50 15 12 8 -145 169 -760 785 -426 426 -782 775 -791 775 -9 0 -23 -10 -32 -22z" />
      </g>
    </svg>
  );
}
