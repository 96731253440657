import MessageState from "./MessageState";
import { Activity, User } from "../../types";
import ProfilePicturesBunch from "../common/ProfilePicturesBunch";
import { ActivityType } from "../../enums";

export default function MessageDetailsHeader({
  selectedMessage,
  participants,
}: Props): JSX.Element {
  return (
    <div className="message-details-header">
      <h1>
        {selectedMessage.description}
        <span className="message-details-header-number">
          #{selectedMessage.number}
        </span>
      </h1>
      <div className="message-details-header-info">
        <div className="message-details-header-state">
          {selectedMessage.type === ActivityType.General && (
            <span className="message-list-item-type">Allgemein</span>
          )}
          <MessageState stateType={selectedMessage.stateType} />
        </div>
        <div className="message-details-header-participants">
          <ProfilePicturesBunch users={participants} />
        </div>
      </div>
    </div>
  );
}

type Props = {
  selectedMessage: Activity;
  participants: User[];
};
