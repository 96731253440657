import React, { SyntheticEvent, useCallback, useState } from "react";
import classNames from "classnames";
import moment from "moment";

import { Meeting } from "../../types";
import {
  MeetingAttendConfirmed,
  MeetingLeaveConfirmed,
  MeetingState,
  UserRoles,
} from "../../enums";
import Protocol from "../icons/Protocol";
import ActionView from "../icons/ActionView";
import { getMeetingTitle, isMeetingReadyToStart } from "../../lib/meeting";
import useCustomerRoles from "../../hooks/useCustomerRoles";

import "../../styles/components/meetings/MeetingListItem.scss";
import MeetingAttend from "./MeetingAttend";
import MeetingConfirmStartDialog from "./MeetingConfirmStartDialog";
import ConfirmDialog from "../common/dialog/ConfirmDialog";
import MeetingAttendInformation from "./MeetingAttendInformation";
import useAttendMeeting from "../../hooks/meetings/useAttendMeeting";
import useMeetingState from "../../hooks/meetings/useMeetingState";
import { useNavigate } from "react-router-dom";
import useHandleMeeting from "../../hooks/meetings/useHandleMeeting";
import AlertDialog from "../common/dialog/AlertDialog";
import MeetingWatchInformation from "./MeetingWatchInformation";

export default function MeetingListItem(props: Meeting): JSX.Element {
  const { state, startDate, id } = props;
  const meetingIdToAttend = id ? id : "";
  const meetingState = useMeetingState(id);
  const customerRoles = useCustomerRoles();
  const navigate = useNavigate();
  const isEmployee = customerRoles.includes(UserRoles.Employee);
  const [attendConfirmed, setAttendConfirmed] = useState(
    MeetingAttendConfirmed.IsToConfirm,
  );
  const [leaveConfirmed, setLeaveConfirmed] = useState(
    MeetingLeaveConfirmed.IsToConfirm,
  );

  const className = classNames("meeting-list-item", {
    "meeting-list-item-closed": state === MeetingState.Closed,
    "meeting-list-item-started": state === MeetingState.Running,
  });

  const startDateTime = moment(startDate).format("LLL");
  const title = getMeetingTitle(props);
  const { isAttending, isLeaving, data } = useAttendMeeting(
    meetingState,
    customerRoles,
    id,
  );

  const meetingAttend = data?.attend;
  const meetingWatch = data?.watch;

  const {
    handleJoinMeeting,
    isParticipationConfirmed,
    setParticipationConfirmed,
  } = useHandleMeeting(id, attendConfirmed);

  const handleCancelConfirmation = useCallback(
    (e: SyntheticEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();
      setParticipationConfirmed(MeetingAttendConfirmed.IsToConfirm);
      setAttendConfirmed(MeetingAttendConfirmed.IsToConfirm);
      setLeaveConfirmed(MeetingLeaveConfirmed.IsToConfirm);
    },
    [setAttendConfirmed, isParticipationConfirmed],
  );

  const meetingFollow = useCallback(
    (e: SyntheticEvent<HTMLDivElement>) => {
      if (meetingAttend) {
        navigate(meetingIdToAttend);
      }
      if (!meetingAttend || meetingWatch) {
        handleJoinMeeting(e);
      }
      if (meetingState !== MeetingState.Running) {
        navigate(meetingIdToAttend);
      }
    },
    [leaveConfirmed, attendConfirmed, isAttending, isLeaving, data],
  );

  return (
    <div role="listitem" className="meeting-list-item-content-wrapper">
      <div className={className} role="link" onClick={(e) => meetingFollow(e)}>
        <Protocol />
        <div className="meeting-list-item-content">
          <h4 className="meeting-list-item-headline">{title}</h4>
          <small className="meeting-list-item-info" role="contentinfo">
            {startDateTime}
          </small>
        </div>
      </div>
      <div className="meeting-list-item-actions" role="toolbar">
        <ActionView className="meeting-list-item-actions-view" />
        <MeetingAttend title={title} id={id} />
      </div>
      {isMeetingReadyToStart(state, customerRoles) && (
        <MeetingConfirmStartDialog meetingId={id} />
      )}
      {!isEmployee &&
        isParticipationConfirmed === MeetingAttendConfirmed.InConfirmation &&
        meetingState === MeetingState.Running &&
        !meetingWatch && (
          <ConfirmDialog
            description={<MeetingAttendInformation title={title} />}
            title="An der Versammlung teilnehmen"
            onConfirm={handleJoinMeeting}
            confirmLabel="Jetzt teilnehmen"
            onCancel={handleCancelConfirmation}
          />
        )}
      {!isEmployee &&
        isParticipationConfirmed === MeetingAttendConfirmed.InConfirmation &&
        meetingState === MeetingState.Running &&
        meetingWatch && (
          <AlertDialog
            description={
              <MeetingWatchInformation meetingId={meetingIdToAttend} />
            }
            title="Teilnahme an der Versammlung nicht möglich"
            onConfirm={(e) => {
              handleJoinMeeting(e as SyntheticEvent<HTMLButtonElement>);
            }}
            confirmLabel="OK"
          />
        )}
    </div>
  );
}
