import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import PageHeader from "../../common/PageHeader";
import Button from "../../common/Button";
import KeyIcon from "../../icons/KeyIcon";
import FluksImg from "../../icons/FluksImg";
import ConfirmDialog from "../../common/dialog/ConfirmDialog";
import { Form } from "react-final-form";

import {
  useGetApiKeyQuery,
  usePostApiKeyMutation,
} from "../../../api/customerApi";
import { useParams } from "react-router-dom";
import moment from "moment/moment";
import { validateRequired } from "../../../lib/formValidate";
import useHasPermission from "../../../hooks/useHasPermission";
import FluksKeyInfoDialog from "../../fluks/FluksKeyInfoDialog";

import "../../../styles/components/pages/management/FluksPage.scss";

export default function FluksPage(): ReactElement {
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [isErrorPostApiKey, setErrorPostApiKey] = useState(false);
  const hasPermission = useHasPermission("CanCreateFluksApiKey");
  const { customerToken = "" } = useParams();

  const { data: apiKey } = useGetApiKeyQuery({ customerToken });
  const [postApiKey, { data, isSuccess, isError }] = usePostApiKeyMutation();

  const onSubmit = useCallback(async () => {
    await postApiKey({ customerToken });
  }, [data]);

  useEffect(() => {
    setErrorPostApiKey(isError);
  }, [isError]);

  useEffect(() => {
    if (data?.apiKey && isSuccess) {
      const file = new File([data.apiKey], "apiKey.txt");
      const url = URL.createObjectURL(file);

      const link = document.createElement("a");
      link.href = url;
      link.download = "ApiKey";
      link.target = "_blank";

      link.dispatchEvent(
        new MouseEvent("click", {
          bubbles: true,
          cancelable: true,
          view: window,
        }),
      );
      setDialogOpen(false);
      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        URL.revokeObjectURL(url);
        link.remove();
      }, 100);
    }
  }, [isSuccess]);

  return (
    <div className="fluks-container">
      <Helmet>
        <title> fluks - Homecase</title>
      </Helmet>
      <PageHeader title="fluks Anbindung" />
      <section className="fluks-container-info-wrapper">
        <div className="key-info-wrapper">
          <h3>fluks API Schlüssel</h3>
          <span className="key-info">
            fluks ermöglicht die Automatisierung von Arbeitsabläufen über
            Unternehmen und Tools hinweg. Damit diese Workflows HOMECASE
            Aktionen und Auslöser enthalten können, muss ein API Schlüssel
            verwendet werden.
          </span>
          {apiKey && (
            <p className="api-key-existing-into-text">
              {`Ein API Schlüssel ist bereits vorhanden. Erstellungsdatum: ${moment
                .utc(apiKey.generatedAtUtc)
                .local()
                .format("L")}`}
            </p>
          )}
          {!apiKey && (
            <p className="api-key-no-generated-into-text">
              Es wurde noch kein API Schlüssel generiert.
            </p>
          )}

          <Button
            leadingIcon={KeyIcon}
            lightblue
            disabled={!hasPermission}
            label="API Schlüssel erstellen"
            onClick={() => setDialogOpen(true)}
          />
        </div>

        <FluksImg className="img-wrapper" />
        {isDialogOpen && (
          <Form
            onSubmit={onSubmit}
            validate={(values) => ({
              remark: validateRequired()(values.remark),
            })}
            render={({ handleSubmit, values }) => {
              return (
                <ConfirmDialog
                  description={
                    <FluksKeyInfoDialog isErrorPostApiKey={isErrorPostApiKey} />
                  }
                  cancelLabel="Abbrechen"
                  confirmLabel="Erstellen und herunterladen"
                  title="API Schlüssel erstellen"
                  onConfirm={handleSubmit}
                  confirmDisabled={!values.remark}
                  onCancel={() => {
                    setDialogOpen(false);
                    setErrorPostApiKey(false);
                  }}
                />
              );
            }}
          />
        )}
      </section>
    </div>
  );
}
