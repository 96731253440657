import React, { SyntheticEvent, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

import ArrowLeft from "../icons/ArrowLeft";
import List from "../icons/List";
import ArrowRight from "../icons/ArrowRight";
import { AgendaItem } from "../../types";

import "../../styles/components/meetings/AgendaNavigation.scss";
import ConfirmDialog from "../common/dialog/ConfirmDialog";
import { useDispatch, useSelector } from "react-redux";
import { store } from "../../store";
import {
  isAgendaItemEditing,
  isPropositionEditing,
} from "../../slices/agendaItemEditingProcessSlice";

export default function AgendaNavigation({
  items,
  selectedItemId,
  meetingId,
  navigationIsVisible,
}: Props): JSX.Element {
  const dispatch = useDispatch();
  const selectedIndex = items.findIndex(({ id }) => id === selectedItemId);
  const selectedItem: AgendaItem = items[selectedIndex];
  const { id: previousItemId } = (selectedIndex > 0 &&
    items[selectedIndex - 1]) || { id: undefined };

  const { id: nextItemId } = (selectedIndex < items.length &&
    items[selectedIndex + 1]) || { id: undefined };

  const headline = selectedItem
    ? `TOP ${
        selectedItem.addition
          ? selectedItem.number + "." + selectedItem.addition
          : selectedItem.number
      }: ${selectedItem.title}`
    : "Tagesordungspunkte";

  const navigate = useNavigate();
  const [isTopLeave, setTopLeave] = useState(false);
  const [isAriaLabel, setAriaLabel] = useState<string | null>("");
  const propositionEditing = useSelector(
    (state: ReturnType<typeof store.getState>) =>
      state.agendaItemEditing.propositionEditing,
  );

  const agendaItemEditing = useSelector(
    (state: ReturnType<typeof store.getState>) =>
      state.agendaItemEditing.agendaItemEditing,
  );

  const handleNavigate = useCallback(
    (e: SyntheticEvent) => {
      const { ariaLabel } = e.currentTarget;
      if (ariaLabel === "Ja") {
        dispatch(isPropositionEditing(false));
        dispatch(isAgendaItemEditing(false));
      }
      const labelText = ariaLabel === "Ja" ? isAriaLabel : ariaLabel;

      switch (labelText) {
        case "Vorheriger Tagesordungspunkt":
          navigate(`agenda/${previousItemId}`);
          break;
        case "Nächster Tagesordungspunkt":
          navigate(`agenda/${nextItemId}`);
          break;
        case "Übersicht Tagesordnung":
          navigate(`../${meetingId}`);
          break;
        default:
          break;
      }

      setTopLeave(false);
    },
    [
      previousItemId,
      nextItemId,
      meetingId,
      propositionEditing,
      agendaItemEditing,
      isAriaLabel,
    ],
  );

  const handleTopLeave = useCallback((e: SyntheticEvent) => {
    setAriaLabel(e.currentTarget.ariaLabel);
    setTopLeave(true);
  }, []);

  return (
    <div className="etv-agenda-navigation">
      <h2 className="etv-agenda-navigation-headline">{headline}</h2>
      {navigationIsVisible && (
        <aside className="etv-agenda-navigation-actions" role="toolbar">
          {previousItemId && (
            <div
              className="etv-agenda-navigation-back"
              role="link"
              onClick={
                propositionEditing || agendaItemEditing
                  ? handleTopLeave
                  : handleNavigate
              }
              aria-label="Vorheriger Tagesordungspunkt"
            >
              <ArrowLeft className="etv-agenda-navigation-icon" />
            </div>
          )}
          {!previousItemId && (
            <span className="etv-agenda-navigation-back etv-agenda-navigation-disabled">
              <ArrowLeft className="etv-agenda-navigation-icon" />
            </span>
          )}
          <div
            className="etv-agenda-navigation-home"
            role="link"
            aria-label="Übersicht Tagesordnung"
            onClick={
              propositionEditing || agendaItemEditing
                ? handleTopLeave
                : handleNavigate
            }
          >
            <List className="etv-agenda-navigation-icon" />
          </div>
          {nextItemId && (
            <div
              className="etv-agenda-navigation-next"
              role="link"
              aria-label="Nächster Tagesordungspunkt"
              onClick={
                propositionEditing || agendaItemEditing
                  ? handleTopLeave
                  : handleNavigate
              }
            >
              <ArrowRight className="etv-agenda-navigation-icon" />
            </div>
          )}
          {!nextItemId && (
            <span className="etv-agenda-navigation-next etv-agenda-navigation-disabled">
              <ArrowRight className="etv-agenda-navigation-icon" />
            </span>
          )}
        </aside>
      )}
      {isTopLeave && (
        <ConfirmDialog
          description={""}
          title="Sie haben ungesicherte Änderungen, sind Sie sicher, dass Sie fortfahren möchten?"
          onConfirm={handleNavigate}
          confirmLabel="Ja"
          onCancel={() => {
            setTopLeave(false);
            setAriaLabel("");
          }}
          cancelLabel="Abbrechen"
        />
      )}
    </div>
  );
}

type Props = {
  items: AgendaItem[];
  selectedItemId?: string;
  meetingId: string;
  navigationIsVisible: boolean;
};
