import { FieldRenderProps } from "react-final-form";
import { Fragment, ReactElement, SyntheticEvent, useCallback } from "react";

import CloseIcon from "../../icons/CloseIcon";
import OtherFile from "../files/OtherFile";
import UnsupportedFile from "../files/UnsupportedFile";
import { PREVIEW_COMPONENTS } from "../../../lib/messages";

import "../../../styles/components/common/form/FilePreview.scss";

export default function FilePreview({
  input,
  meta,
}: FieldRenderProps<File[], HTMLElement>): ReactElement {
  const files: File[] = [...input.value];

  const removeFile = useCallback(
    (e: SyntheticEvent) => {
      const fileName = e.currentTarget.getAttribute("data-value");
      const value = files.filter((f) => f.name !== fileName);
      input.onChange(value.length ? value : meta.initial);
    },
    [files],
  );

  return (
    <Fragment>
      {files.map((file, key) => {
        const fileError =
          meta.error &&
          meta.error.find(
            (e: { errorMessage: string; name: string }) => e.name === file.name,
          );
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const Component = PREVIEW_COMPONENTS[file.type] || OtherFile;
        return (
          <div
            className="file-preview-item"
            key={key}
            data-testid="file-preview-item"
          >
            <span
              className="file-preview-item-remove"
              data-testid="file-preview-item-remove"
              onClick={removeFile}
              data-value={file.name}
            >
              <CloseIcon />
            </span>
            {!fileError && <Component file={file} width={60} height={60} />}
            {fileError && (
              <UnsupportedFile
                file={file}
                error={fileError}
                width={60}
                height={60}
              />
            )}
            <span className="file-preview-item-name">{file.name}</span>
          </div>
        );
      })}
    </Fragment>
  );
}
