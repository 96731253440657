import "../../../styles/components/pages/meetings/EventLogPage.scss";
import React from "react";
import { useGetEventLogQuery } from "../../../api/meetingApi";
import { useParams } from "react-router-dom";
import EventLogItem from "../../meetings/EventLogItem";

export default function EventLogPage() {
  const {
    customerToken = "",
    meetingId = "",
    facilityObjectId = "",
  } = useParams();

  const { data } = useGetEventLogQuery({
    customerToken,
    meetingId,
    facilityObjectId,
  });

  return (
    <section className="event-log-wrapper">
      <h2 className="event-log-headline">Ereignis Log</h2>
      <div className="event-log-item-list-wrapper">
        {data?.map((item, index) => {
          return <EventLogItem key={index} item={item} />;
        })}
      </div>
    </section>
  );
}
