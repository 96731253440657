import useGetPermissions from "./useGetPermissions";
import { PermissionInternalCode } from "../types";

export default function useHasPermission(
  permissionInternalCode: PermissionInternalCode,
): boolean {
  const permissions = useGetPermissions();

  return Boolean(
    permissions.find(
      ({ internalCode }) => internalCode === permissionInternalCode,
    ),
  );
}
