import React, { ReactElement, useCallback, useEffect, useState } from "react";
import useMeetingState from "../../hooks/meetings/useMeetingState";
import useUpdateMeetingState from "../../hooks/meetings/useUpdateMeetingState";
import useCustomerRoles from "../../hooks/useCustomerRoles";
import Button from "../common/Button";
import ConfirmDialog from "../common/dialog/ConfirmDialog";
import {
  isMeetingReadToClose,
  isMeetingReadToShare,
  isMeetingReadyToStart,
} from "../../lib/meeting";
import UpdateDialog from "../common/dialog/UpdateDialog";
import ErrorDialog from "../common/dialog/ErrorDialog";
import { useSynchronisation } from "../../hooks/useSynchronisation";
import { MeetingState, SynchronisationMethod } from "../../enums";
import useValidateMeeting from "../../hooks/meetings/useValidateMeeting";
import AlertDialog from "../common/dialog/AlertDialog";
import MeetingValidationError from "./MeetingValidationError";
import MeetingConfirmStartDialog from "./MeetingConfirmStartDialog";
import { useParams } from "react-router-dom";

export default function MeetingControl(): ReactElement {
  const [isCloseToConfirm, setCloseToConfirm] = useState(false);
  const [isShareToConfirm, setShareToConfirm] = useState(false);
  const [isValidationError, setIsValidationError] = useState(false);
  const [isCloseMeeting, setIsCloseMeeting] = useState(false);

  const meetingState = useMeetingState();
  const { meetingId = "" } = useParams();
  const { validate, isValidating, isValid, failureReasons } =
    useValidateMeeting();
  const { closeMeeting, shareMeeting, isLoading, retry, error, isSuccess } =
    useUpdateMeetingState();
  const customerRoles = useCustomerRoles();
  const { sync } = useSynchronisation();

  const handleConfirmCloseMeeting = useCallback(() => {
    setIsCloseMeeting(true);
    setCloseToConfirm(!isCloseToConfirm);
  }, [setCloseToConfirm, isCloseToConfirm]);

  const handleConfirmShareMeeting = useCallback(() => {
    setShareToConfirm(!isShareToConfirm);
  }, [setShareToConfirm, isShareToConfirm]);

  useEffect(() => {
    if (isLoading) {
      setCloseToConfirm(false);
    }
  }, [isLoading, setCloseToConfirm]);

  useEffect(() => {
    if (meetingState === 3) {
      setIsCloseMeeting(false);
    }
  }, [meetingState, setIsCloseMeeting]);

  useEffect(() => {
    if (isValidating) {
      setShareToConfirm(false);
    }
  }, [isValidating, setShareToConfirm]);

  useEffect(() => {
    if (isSuccess) {
      sync(SynchronisationMethod.MeetingStateChanged);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isValid && meetingState === MeetingState.EmployeePreview) {
      shareMeeting();
    }
    if (!isValid && failureReasons) {
      setIsValidationError(true);
    }
  }, [isValid, setIsValidationError, failureReasons]);

  const handleAlertConfirm = useCallback(() => {
    setIsValidationError(false);
  }, [setIsValidationError]);
  return (
    <div className="etv-meeting-control" data-testid="etv-meeting-control">
      {isMeetingReadToClose(meetingState, customerRoles) && (
        <Button
          label="Versammlung beenden"
          red
          onClick={handleConfirmCloseMeeting}
          disabled={isLoading}
        />
      )}
      {isMeetingReadyToStart(meetingState, customerRoles) && (
        <MeetingConfirmStartDialog meetingId={meetingId} disabled={isLoading} />
      )}
      {isMeetingReadToShare(meetingState, customerRoles) && (
        <Button
          label="Für Eigentümer anzeigen"
          lightblue
          onClick={handleConfirmShareMeeting}
          disabled={isLoading}
        />
      )}
      {isCloseToConfirm && (
        <ConfirmDialog
          title="Versammlung beenden"
          description="Möchten Sie die Versammlung wirklich beenden?"
          onConfirm={closeMeeting}
          onCancel={handleConfirmCloseMeeting}
        />
      )}
      {isShareToConfirm && (
        <ConfirmDialog
          title="Für Eigentümer anzeigen"
          description="Wollen Sie wirklich diese Versammlung für Eigentümer anzeigen?"
          onConfirm={validate}
          onCancel={handleConfirmShareMeeting}
          confirmLabel="Anzeigen"
        />
      )}
      {isLoading && meetingState === 0 && (
        <UpdateDialog
          description="Die Versammlung wird für die Eigentümer veröffentlicht."
          title="Für Eigentümer anzeigen"
        />
      )}
      {isLoading && isCloseMeeting && (
        <UpdateDialog
          description="Die Versammlung wird für alle Teilnehmer beendet."
          title="Versammlung beenden"
        />
      )}
      {isValidating && meetingState === 0 && (
        <UpdateDialog
          description="Die Eigentümerversammlung wird für die Freigabe geprüft"
          title="Eigentümerversammlung wird geprüft."
        />
      )}
      {isValidationError && failureReasons && (
        <AlertDialog
          title="Die Versammlung kann für Eigentümer nicht angezeigt bzw. gestartet werden."
          onConfirm={handleAlertConfirm}
          description={
            <MeetingValidationError failureReasons={failureReasons} />
          }
        />
      )}
      <ErrorDialog
        description="Ein unerwarteter Fehler ist aufgetreten. Bitte versuchen Sie es erneut"
        title="Es ist ein Fehler aufgetreten!"
        onRetry={retry}
        error={error}
      />
    </div>
  );
}
