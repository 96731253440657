import { useNavigate, useParams } from "react-router-dom";
import React, { SyntheticEvent, useCallback } from "react";

import Button from "../common/Button";

import "../../styles/components/messages/MessageListHeader.scss";
import SelectField from "../common/SelectField";
import { UserRoles } from "../../enums";
import useTenanciesAndOwnershipsOptions from "../../hooks/documents/useTenanciesAndOwnershipsOptions";
import useCustomerRoles from "../../hooks/useCustomerRoles";
import classNames from "classnames";

export default function MessageListHeader({
  selectedTenanciesAndOwnershipsOptions,
  setSelectedTenanciesAndOwnershipsOption,
  isLoading,
}: Props): JSX.Element {
  const navigate = useNavigate();
  const { messageId = "" } = useParams();
  const handleNewMessage = useCallback(() => {
    if (!!messageId) {
      return navigate("../neu");
    }
    return navigate("./neu");
  }, [navigate, messageId]);

  const roles = useCustomerRoles();
  const showDocumentsForManagement = roles.includes(UserRoles.Employee);

  const tenanciesAndOwnershipsOptions = useTenanciesAndOwnershipsOptions(
    !showDocumentsForManagement,
  );

  const onSelect = useCallback((e: SyntheticEvent<HTMLSelectElement>) => {
    const selectedOption = parseInt(e.currentTarget.value, 10);
    setSelectedTenanciesAndOwnershipsOption(selectedOption);
  }, []);

  const classNameMessageList = classNames("message-list-header", {
    manager: showDocumentsForManagement,
  });

  return (
    <div className={classNameMessageList} data-testid="message-list-header">
      {showDocumentsForManagement && (
        <div className="documents-page-container-selector">
          <SelectField
            options={tenanciesAndOwnershipsOptions}
            label=""
            onChange={onSelect}
            unselectable="on"
            value={
              selectedTenanciesAndOwnershipsOptions !== null
                ? selectedTenanciesAndOwnershipsOptions
                : ""
            }
          />
        </div>
      )}
      {!showDocumentsForManagement && !isLoading && (
        <Button label="Neue Nachricht" lightblue onClick={handleNewMessage} />
      )}
    </div>
  );
}

type Props = {
  selectedTenanciesAndOwnershipsOptions: number | null;
  setSelectedTenanciesAndOwnershipsOption: (item: number) => void;
  isLoading: boolean;
};
