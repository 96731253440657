import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { DOCUMENTS_REDUCER_PATH } from "../reducerPaths";
import { BASE_URL, prepareHeaders } from "./utils";
import { Document, DocumentRequest, DocumentsRequest } from "../types";
import { DocumentsQueryType } from "../enums";

export const documentApi = createApi({
  reducerPath: DOCUMENTS_REDUCER_PATH,
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders,
  }),

  endpoints: (build) => ({
    getOnlineDocuments: build.query<Document[], DocumentsRequest>({
      query: ({
        customerToken,
        facilityObjectId,
        queryType,
        tenancyOrOwnershipId,
      }) => ({
        url: `/customers/${customerToken}/facilityObjects/${facilityObjectId}/queryOnlineDocuments`,
        params: { queryType, tenancyOrOwnershipId },
      }),
      transformResponse: (data: Document[]) => {
        const documents: Document[] = data.map((element) => {
          if (element.category === "") {
            return { ...element, category: "Sonstiges" };
          }
          return element;
        });
        return documents.sort((a, b) => {
          const firstDate = Date.parse(a.createDateUTC);
          const secondDate = Date.parse(b.createDateUTC);
          if (firstDate < secondDate) {
            return 1;
          }
          if (firstDate > secondDate) {
            return -1;
          }
          return 0;
        });
      },
    }),
    getOnlineDocument: build.query<Blob, DocumentRequest>({
      query: ({ customerToken, facilityObjectId, documentId }) => ({
        url: `/customers/${customerToken}/facilityObjects/${facilityObjectId}/onlinedocuments/${documentId}`,
        responseHandler: async (response) => {
          if (response.status === 200) {
            const blob = await response.blob();
            return Promise.resolve(blob);
          }
          return Promise.resolve(null);
        },
      }),
    }),
    getOnlineDocumentActivityNumber: build.query<number, DocumentRequest>({
      query: ({ customerToken, facilityObjectId, documentId }) => ({
        url: `/customers/${customerToken}/facilityObjects/${facilityObjectId}/onlinedocuments/${documentId}/activityNumber`,
      }),
    }),
    getLatestOnlineDocuments: build.query<Document[], DocumentsRequest>({
      queryFn: async (
        { customerToken, facilityObjectId },
        api,
        extraOptions,
        baseQuery,
      ) => {
        const response = await Promise.all([
          await baseQuery({
            url: `/customers/${customerToken}/facilityObjects/${facilityObjectId}/queryOnlineDocuments`,
            params: { queryType: DocumentsQueryType.FacilityObjectDocuments },
          }),
          await baseQuery({
            url: `/customers/${customerToken}/facilityObjects/${facilityObjectId}/queryOnlineDocuments`,
            params: { queryType: DocumentsQueryType.CurrentUserDocuments },
          }),
        ]);
        const data = response
          .map(({ data }) => data)
          .flat()
          .filter((d) => d !== undefined) as Document[];

        data.sort((a, b) => {
          const firstDate = Date.parse(a.createDateUTC);
          const secondDate = Date.parse(b.createDateUTC);
          if (firstDate < secondDate) {
            return 1;
          }
          if (firstDate > secondDate) {
            return -1;
          }
          return 0;
        });

        return {
          data: data.slice(0, 3),
        };
      },
    }),
  }),
});

export const {
  useGetLatestOnlineDocumentsQuery,
  useLazyGetOnlineDocumentsQuery,
  useLazyGetOnlineDocumentQuery,
  useLazyGetOnlineDocumentActivityNumberQuery,
} = documentApi;
