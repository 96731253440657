import { Customer, FacilityObject, ImageSize } from "../types";
import { UserRoles } from "../enums";

const LOGO_SRC_SET = {
  "180x45": "1x",
  "360x90": "2x",
  "540x130": "3x",
};

export const AUTHENTICATION_PATH = "/anmelden";

export function getCustomerLogoSources(facilityObject?: FacilityObject):
  | {
      src: string;
      srcSet: string;
    }
  | undefined {
  if (facilityObject) {
    const srcsetArray = Object.entries(LOGO_SRC_SET).map(
      ([size, multiplier]) => {
        return `/${facilityObject.token}/logo_${size}_${facilityObject.logoVersionStamp}.png ${multiplier}`;
      },
    );
    const [src] = srcsetArray[0].split(" ");
    return {
      srcSet: srcsetArray.join(", "),
      src,
    };
  }
}

export function getCustomerLogo(managementItem?: Customer):
  | {
      src: string;
      srcSet: string;
    }
  | undefined {
  if (managementItem) {
    const srcsetArray = Object.entries(LOGO_SRC_SET).map(
      ([size, multiplier]) => {
        return `/${managementItem.token}/logo_${size}_${managementItem.logoVersionStamp}.png ${multiplier}`;
      },
    );
    const [src] = srcsetArray[0].split(" ");
    return {
      srcSet: srcsetArray.join(", "),
      src,
    };
  }
}

export function getProfilePictureSources(
  { width, height }: ImageSize,
  userSid: string | number,
  profileVersionStamp: string,
): {
  src: string;
  srcSet: string;
} {
  const srcSetArray = [1, 2, 3].map(
    (multiplier) =>
      `/users/${userSid}_${width * multiplier}x${
        height * multiplier
      }_fill_${profileVersionStamp}.jpg`,
  );

  return {
    srcSet: srcSetArray.join(", "),
    src: srcSetArray[0],
  };
}

export function isEmployeeByRoles(roles: UserRoles[]): boolean {
  return roles.includes(UserRoles.Employee);
}

export function isOwnerByRoles(roles: UserRoles[]): boolean {
  return (
    roles.includes(UserRoles.Owner) || roles.includes(UserRoles.ObjectOwner)
  );
}

export async function blobToDataUrl(blob: Blob): Promise<string | undefined> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = (ev: ProgressEvent<FileReader>) =>
      resolve(ev.target?.result?.toString());
    reader.onerror = (ev) => reject(ev);
  });
}

export async function readFileBinary(file: File): Promise<ArrayBuffer> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    reader.onload = () => resolve(reader.result);
    reader.onerror = (ev) => reject(ev);
  });
}

export function isSameFile(a: File, b: File): boolean {
  return a.name === b.name && a.size === b.size;
}

export function hexToRGBA(hex: string, alpha = 1): string {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

export function getContentSize(contentSize: number) {
  let size = "Byte";
  let count = 0;
  if (contentSize < 1024) {
    size = "Byte";
    count = contentSize;
  }
  if (contentSize > 1024) {
    count = contentSize / 1024;
    size = "KB";
    if (count > 1024) {
      size = "MB";
      count = count / 1024;
    }
  }
  return { size, count };
}
