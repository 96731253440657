import React, { Fragment } from "react";
import { useParams } from "react-router-dom";

import AgendaList from "../../meetings/AgendaList";
import AgendaListSkeleton from "../../skeleton/AgendaListSkeleton";

import { useGetAgendaItemsQuery } from "../../../api/agendaItemApi";
import FetchApiError from "../../common/FetchApiError";

export default function AgendaListPage(): JSX.Element {
  const {
    customerToken = "",
    meetingId = "",
    agendaItemId,
    facilityObjectId = "",
  } = useParams();
  const {
    data = [],
    error,
    isSuccess,
    isFetching,
    isError,
  } = useGetAgendaItemsQuery({
    customerToken,
    meetingId,
    facilityObjectId,
    includePropositions: true,
  });

  return (
    <Fragment>
      {isError && <FetchApiError error={error} />}
      {!agendaItemId && isSuccess && data.length > 0 && (
        <AgendaList agendaItems={data} />
      )}
      {!isSuccess && isFetching && <AgendaListSkeleton />}
    </Fragment>
  );
}
