import { MeetingDataIntegrityFailureReason } from "../../types";
import "../../styles/components/meetings/MeetingValidationError.scss";
import { useParams } from "react-router-dom";
import { getFailureReasonText } from "../../lib/meeting";
import { agendaItemApi } from "../../api/agendaItemApi";

export default function MeetingValidationError({
  failureReasons,
}: Props): JSX.Element {
  const {
    customerToken = "",
    facilityObjectId = "",
    meetingId = "",
  } = useParams();
  const { data = [] } = agendaItemApi.endpoints.getAgendaItems.useQueryState({
    customerToken,
    meetingId,
    facilityObjectId,
  });
  return (
    <div className="meeting-validation-error">
      <span>
        Die Versammlung kann für Eigentümer nicht angezeigt bzw. gestartet
        werden, da es folgende Daten fehlen:
      </span>
      <ul className="meeting-validation-error-list">
        {failureReasons.map((reason, key) => (
          <li className="meeting-validation-error-item" key={key}>
            {getFailureReasonText(reason, data)}
          </li>
        ))}
      </ul>
    </div>
  );
}

type Props = {
  failureReasons: MeetingDataIntegrityFailureReason[];
};
